import React from 'react';
import { Link } from 'react-router-dom';

const Affilage = () => {

  return (
    <div className="container mt-5">
      <div className="row g-4">
        {/* MKQ Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affilate_MKQ" className="btn btn-primary w-100">
                MKQ
              </Link>
            </div>
          </div>
        </div>

        {/* PLO Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affilate_PLO" className="btn btn-primary w-100">
                PLO
              </Link>
            </div>
          </div>
        </div>

        {/* ABC Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affilate_ABC" className="btn btn-primary w-100">
                ABC
              </Link>
            </div>
          </div>
        </div>

        {/* AFP Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affliate_AFP" className="btn btn-primary w-100">
                AFP
              </Link>
            </div>
          </div>
        </div>

        {/* AFJ Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affliate_AFJ" className="btn btn-primary w-100">
                AFJ
              </Link>
            </div>
          </div>
        </div>

        {/* ABA Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affliate_ABA" className="btn btn-primary w-100">
                ABA
              </Link>
            </div>
          </div>
        </div>

        {/* MKP Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affliate_MKP" className="btn btn-primary w-100">
                MKP
              </Link>
            </div>
          </div>
        </div>

        {/* AFH Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/Affilate_AFH" className="btn btn-primary w-100">
                AFH
              </Link>
            </div>
          </div>
        </div>

        {/* AFI Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affilate_AFO" className="btn btn-primary w-100">
                AFO
              </Link>
            </div>
          </div>
        </div>

        {/* AFO Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affilate_AFI" className="btn btn-primary w-100">
                AFI
              </Link>
            </div>
          </div>
        </div>

        {/* AFM Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affilate_AFM" className="btn btn-primary w-100">
                AFM
              </Link>
            </div>
          </div>
        </div>

        {/* MKD Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affilate_MKD" className="btn btn-primary w-100">
                MKD
              </Link>
            </div>
          </div>
        </div>

        {/* RKA Button */}
        <div className="col">
          <div className="card shadow-sm border-light">
            <div className="card-body text-center">
              <Link to="/affilate_RKA" className="btn btn-primary w-100">
                RKA
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affilage;
