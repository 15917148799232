// firebase.js
import { initializeApp, getApps } from 'firebase/app';
import { getDatabase } from 'firebase/database';

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyDxmtmIOOpybA01FACux_xCCK3k213GRUs",
  authDomain: "fast-pagoda-438605-c6.firebaseapp.com",
  databaseURL: "https://fast-pagoda-438605-c6-default-rtdb.firebaseio.com",
  projectId: "fast-pagoda-438605-c6",
  storageBucket: "fast-pagoda-438605-c6.firebasestorage.app",
  messagingSenderId: "567040690003",
  appId: "1:567040690003:web:801b6ad4bd828b82fc03a6",
  measurementId: "G-3ZT0F0XL8Y"
};

// Initialize Firebase only if it has not been initialized before
let app;
if (getApps().length === 0) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0]; // Use the existing app
}

// Get a reference to the database
const database = getDatabase(app);

// Function to check if the database is initialized successfully
const checkDatabaseConnection = () => {
  if (database) {
    console.log('Firebase Database initialized successfully!');
  } else {
    console.error('Failed to initialize Firebase Database.');
  }
};

// Call the function to check connection
checkDatabaseConnection();

export { database };
