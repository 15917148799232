import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { HiMiniTableCells } from "react-icons/hi2";
import { ref, set, remove, onValue } from "firebase/database";
import { database } from "./data_user"; // Adjust the import according to your setup
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { Modal, Button } from "react-bootstrap";

function Affilate_PLO() {
  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [newClient, setNewClient] = useState({
    id: "",
    clientId: "",
    clientName: "",
    clientNumber: "",
    clientTelegram: "",
    deposit: "",
    date: "",
    registerAccount: "",
    stage: "", // Ensure stage is included
    description: "",
    staffName: "",
  });
  const [expandedClientId, setExpandedClientId] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = () => {
    const clientsRef = ref(database, "clients/");
    onValue(clientsRef, (snapshot) => {
      const fetchedClients = [];
      snapshot.forEach((childSnapshot) => {
        const clientData = childSnapshot.val();
        fetchedClients.push({ id: childSnapshot.key, ...clientData });
      });
      setClients(
        fetchedClients.filter((client) => client.staffName === "PLO")
      );
    });
  };

  const handleSort = (column) => {
    let direction = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: column, direction });
  };

  const sortedClients = [...clients].sort((a, b) => {
    if (sortConfig.key === null) return 0;
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];
    if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  const filteredClients = sortedClients.filter((client) => {
    const isInDateRange =
      (!startDate || new Date(client.date) >= new Date(startDate)) &&
      (!endDate || new Date(client.date) <= new Date(endDate));
    return (
      Object.values(client).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      ) && isInDateRange
    );
  });

  const totalEntries = filteredClients.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);
  const currentEntries = filteredClients.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleReset = () => {
    setSearchTerm("");
    setStartDate("");
    setEndDate("");
    setCurrentPage(1);
    setNewClient({
      id: "",
      clientId: "",
      clientName: "",
      clientNumber: "",
      clientTelegram: "",
      deposit: "",
      date: "",
      registerAccount: "",
      stage: "", // Ensure stage is reset
      description: "",
      staffName: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClient((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditClient = (client) => {
    setNewClient(client);
    setShowModal(true);
  };

  const handleUpdateClient = (e) => {
    e.preventDefault();
    if (newClient.clientId && newClient.clientName) {
      Swal.fire({
        title: "Confirm Update",
        text: "Are you sure you want to update this client?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!",
      }).then((result) => {
        if (result.isConfirmed) {
          set(ref(database, "clients/" + newClient.id), newClient)
            .then(() => {
              fetchClients();
              Swal.fire("Updated!", "The client has been updated.", "success");
              setShowModal(false);
              handleReset();
            })
            .catch((error) => {
              console.error("Error updating client in Firebase:", error);
              Swal.fire(
                "Error!",
                "There was an error updating the client.",
                "error"
              );
            });
        }
      });
    }
  };

  const handleDeleteClient = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        remove(ref(database, "clients/" + id))
          .then(() => {
            const updatedClients = clients.filter((client) => client.id !== id);
            setClients(updatedClients);
            Swal.fire("Deleted!", "The client has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting client in Firebase:", error);
            Swal.fire(
              "Error!",
              "There was an error deleting the client.",
              "error"
            );
          });
      }
    });
  };

  return (
    <div className="container mt-1">
      <div className="bg-dark p-3" style={{ borderRadius: "10px" }}>
        <HiMiniTableCells style={{ color: "white", fontSize: "2rem" }} />
        <span style={{ color: "white", fontSize: "15px" }}>
          Display Client PLO
        </span>
        <div className="border-bottom mb-2"></div>
        <div className="mb-3">
          <div className="row">
            <div className="col-sm-2 mb-2">
              <select
                style={{ padding: "6px", margin: "0px", width: "60px" }}
                className="form-select"
                onChange={handleEntriesPerPageChange}
                value={entriesPerPage}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
            <div className="col-sm-2 mb-2">
              <input
                type="text"
                style={{ margin: "0px", padding: "6px" }}
                className="form-control"
                placeholder="Search..."
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
            </div>
            <div className="col-sm-2 mb-2">
              <input
                type="date"
                placeholder="Start Date"
                className="form-control"
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </div>
            <div className="col-sm-3 mb-2">
              <input
                type="date"
                placeholder="End Date"
                className="form-control"
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </div>
            <div className="col-sm-3">
              <button className="btn btn-secondary" onClick={handleReset}>
                Reset
              </button>
            </div>
          </div>
        </div>

        {/* Responsive Table */}
        <div className="table-responsive">
          <table className="table table-dark table-hover">
            <thead>
              <tr>
                <th>ID</th>
                <th className="hide-on-mobile">Date</th>
                <th className="hide-on-mobile">C.ID</th>
                <th>C.Name</th>
                <th className="hide-on-mobile">C.Number</th>
                <th>C.TG</th>
                <th className="hide-on-mobile">DP($)</th>
                <th>Rg.Account</th>
                <th className="hide-on-mobile">Stage</th>
                <th className="hide-on-mobile">Staff Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((client) => (
                <React.Fragment key={client.id}>
                  <tr
                    onClick={() => {
                      if (window.innerWidth <= 768) {
                        setExpandedClientId(
                          expandedClientId === client.id ? null : client.id
                        );
                      }
                    }}
                  >
                    <td>{client.id}</td>
                    <td className="hide-on-mobile">{client.date}</td>
                    <td className="hide-on-mobile">{client.clientId}</td>
                    <td>{client.clientName}</td>
                    <td className="hide-on-mobile">{client.clientNumber}</td>
                    <td>{client.clientTelegram}</td>
                    <td className="hide-on-mobile">
                      <span>$ </span>
                      {client.deposit}
                    </td>
                    <td>{client.registerAccount}</td>
                    <td className="hide-on-mobile">{client.stage}</td>{" "}
                    {/* Show stage here */}
                    <td className="hide-on-mobile">{client.staffName}</td>
                    <td style={{ padding: "0px" }}>
                      <button
                        style={{ width: "auto", height: "auto" }}
                        onClick={() => handleEditClient(client)}
                        className="btn btn-warning btn-sm"
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        style={{
                          width: "auto",
                          height: "auto",
                          marginLeft: "3px",
                        }}
                        onClick={() => handleDeleteClient(client.id)}
                        className="btn btn-danger btn-sm ml-2"
                      >
                        <MdDeleteOutline />
                      </button>
                    </td>
                  </tr>
                  {expandedClientId === client.id &&
                    window.innerWidth <= 768 && (
                      <tr>
                        <td colSpan="11">
                          <div>
                            <p>Additional Info for {client.clientName}</p>
                          </div>
                        </td>
                      </tr>
                    )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div style={{ color: "white" }}>{`Showing ${
            (currentPage - 1) * entriesPerPage + 1
          } to ${Math.min(
            currentPage * entriesPerPage,
            totalEntries
          )} of ${totalEntries} entries`}</div>
          <ul className="pagination mb-0">
            <li
              className="page-item"
              onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
            >
              <a className="page-link" href="#">
                «
              </a>
            </li>
            {[...Array(totalPages).keys()].map((page) => (
              <li
                key={page}
                className={`page-item ${
                  page + 1 === currentPage ? "active" : ""
                }`}
                onClick={() => handlePageChange(page + 1)}
              >
                <a className="page-link" href="#">
                  {page + 1}
                </a>
              </li>
            ))}
            <li
              className="page-item"
              onClick={() =>
                handlePageChange(Math.min(currentPage + 1, totalPages))
              }
            >
              <a className="page-link" href="#">
                »
              </a>
            </li>
          </ul>
        </div>

        {/* Modal for Editing Client */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="editClientForm" onSubmit={handleUpdateClient}>
              <div className="mb-2">
                <label htmlFor="clientId" className="form-label">
                  Client ID
                </label>
                <input
                  type="text"
                  name="clientId"
                  value={newClient.clientId}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-2">
                <label htmlFor="clientName" className="form-label">
                  Client Name
                </label>
                <input
                  type="text"
                  name="clientName"
                  value={newClient.clientName}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-2">
                <label htmlFor="clientNumber" className="form-label">
                  Client Number
                </label>
                <input
                  type="text"
                  name="clientNumber"
                  value={newClient.clientNumber}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-2">
                <label htmlFor="clientTelegram" className="form-label">
                  Client Telegram
                </label>
                <input
                  type="text"
                  name="clientTelegram"
                  value={newClient.clientTelegram}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="deposit" className="form-label">
                  Deposit
                </label>
                <input
                  type="number"
                  name="deposit"
                  value={newClient.deposit}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="date" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  name="date"
                  value={newClient.date}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-2">
                <label htmlFor="stage" className="form-label">
                  Stage
                </label>
                <select
                  name="stage"
                  value={newClient.stage}
                  onChange={handleInputChange}
                  className="form-select"
                  required
                >
                  <option value="">Select Stage</option>
                  <option value="Level 1">Level 1</option>
                  <option value="Level 2">Level 2</option>
                  <option value="Level 3">Level 3</option>
                  <option value="Level 4">Level 4</option>
                  <option value="Level 5">Level 5</option>
                </select>
              </div>
              {/* Add additional fields as necessary */}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit" form="editClientForm">
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Affilate_PLO;
