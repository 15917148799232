// App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./screen/ProtectedRoute"; // Import your ProtectedRoute
import MainContain from "./screen/MainContain";
import Datauser from "./screen/Datauser";
import Login from "./screen/Login";
import Affilate_MKQ from "./screen/Affilate_MKQ";
import User5 from "./screen/User5";
import Affilage from "./screen/Affilate";
import DataAdmin from "./screen/DataAdmin";
// import All User Routes
import Affilate_PLO from "./screen/listAffilate/Affilate_PLO";
import Affilate_ABC from "./screen/listAffilate/Affilate_ABC";
import Affliate_AFP from "./screen/listAffilate/Affliate_AFP";
import Affliate_AFJ from "./screen/listAffilate/Affliate_AFJ";
import Affliate_ABA from './screen/listAffilate/Affliate_ABA';
import Affliate_MKP from './screen/listAffilate/Affliate_MKP';
import Affilate_AFH from './screen/listAffilate/Affilate_AFH';
import Affilate_AFO from './screen/listAffilate/Affilate_AFO';
import Affilate_AFI from './screen/listAffilate/Affilate_AFI';
import Affilate_AFM from './screen/listAffilate/Affilate_AFM.js';
import Affilate_MKD from './screen/listAffilate/Affilate_MKD';
import Affilate_RKA from './screen/listAffilate/Affilate_RKA';
// import All User Routes
import "./App.css";
const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Public Route (Login) */}
                <Route path="/" element={<Login />} />
                {/* Protected Routes */}
                <Route path="/datauser" element={<ProtectedRoute><Datauser /></ProtectedRoute>} />
                {/* Main Container for additional routes */}
                {/* <Route element={<ProtectedRoute><MainContain /></ProtectedRoute>}> */}
                <Route element={<MainContain />}>
                    <Route path="/affilate_MKQ" element={<Affilate_MKQ />} />
                    <Route path="/user5" element={<User5 />} />
                    <Route path="/dataAdmin" element={<DataAdmin />} />
                    <Route path="/affilate" element={<Affilage />} />
                    <Route path="/affilate_PLO" element={<Affilate_PLO />} />
                    <Route path="/affilate_ABC" element={<Affilate_ABC />} />
                    <Route path="/affliate_AFP" element={<Affliate_AFP />} />
                    <Route path="/affliate_AFJ" element={<Affliate_AFJ />} />
                    <Route path="/affliate_ABA" element={<Affliate_ABA />} />
                    <Route path="/affliate_MKP" element={<Affliate_MKP />} />
                    <Route path="/affilate_AFH" element={<Affilate_AFH />} />
                    <Route path="/affilate_AFO" element={<Affilate_AFO />} />
                    <Route path="/affilate_AFI" element={<Affilate_AFI />} />
                    <Route path="/affilate_AFM" element={<Affilate_AFM />} />
                    <Route path="/affilate_MKD" element={<Affilate_MKD />} />
                    <Route path="/affilate_RKA" element={<Affilate_RKA />} />
                       
                </Route>
                {/* 404 Route */}
                <Route path="*" element={<div>404 - Page Not Found</div>} />
            </Routes>
        </BrowserRouter>
    );
};
export default App;
