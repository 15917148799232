
// import React, { useState } from "react";
// import { NavLink, Outlet } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Navbar, Nav } from 'react-bootstrap';
// import { FaBars } from 'react-icons/fa'; // Ensure `react-icons` is installed

// const MainContain = () => {
//     const [expanded, setExpanded] = useState(false);

//     const handleToggle = () => {
//         setExpanded(prevExpanded => !prevExpanded);
//     };
    

//     return (
//         <div className="container mt-4" style={{ padding: "8px" }}>
//             <h1 className="text-center border-bottom fw-bolder" style={{ color: "white", borderRadius: "5px", padding: "10px" }}>
//                 Welcome Back to ICG Club
//             </h1>
//             <Navbar expanded={expanded} expand="lg" variant="light" style={{ color: "white", borderRadius: "5px", padding: "10px" }}>
//                 <Navbar.Brand href="/mainpage" style={{ color: 'white' }}>ICG Club</Navbar.Brand>
//                 <Navbar.Toggle 
//                     aria-controls="basic-navbar-nav" 
//                     onClick={handleToggle} 
//                     style={{ backgroundColor: 'transparent' }} // Optional: customize toggle button
//                 />
//                 <Navbar.Collapse id="basic-navbar-nav" style={{ padding: "0 1rem" }}>
//                     <Nav className="me-auto">
//                         {/* User Links */}
//                         <NavLink 
//                             className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
//                             to="/user5" 
//                             style={{ color: 'white', marginLeft: "10px" }}
//                             onClick={handleToggle}
//                         >
//                             All Data User
//                         </NavLink>
//                         <NavLink 
//                             className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
//                             to="/user1" 
//                             style={{ color: 'white', marginLeft: "10px" }}
//                             onClick={handleToggle}
//                         >
//                             John Doe
//                         </NavLink>
//                         <NavLink 
//                             className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
//                             to="/user2" 
//                             style={{ color: 'white', marginLeft: "10px" }}
//                             onClick={handleToggle}
//                         >
//                             Jane Smith
//                         </NavLink>
//                         <NavLink 
//                             className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
//                             to="/user3" 
//                             style={{ color: 'white', marginLeft: "10px" }}
//                             onClick={handleToggle}
//                         >
//                            Alice Johnson
//                         </NavLink>
//                         <NavLink 
//                             className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
//                             to="/user4" 
//                             style={{ color: 'white', marginLeft: "10px" }}
//                             onClick={handleToggle}
//                         >
//                            Bob Brown
//                         </NavLink>
//                         {/* New Link for User Accounts Management */}
//                         <NavLink 
//                             className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
//                             to="/useraccount" 
//                             style={{ color: 'white', marginLeft: "10px" }}
//                             onClick={handleToggle}
//                         >
//                             User Accounts
//                         </NavLink>
//                     </Nav>
//                     <NavLink 
//                         className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
//                         to="/" 
//                         style={{ color: 'white', marginLeft: "10px" }}
//                         onClick={handleToggle}
//                     >
//                         SignOut
//                     </NavLink>
//                 </Navbar.Collapse>
//             </Navbar>
//           <div className="container main-content">

//           </div>
//            <div>
//                <Outlet />
//            </div>
//         </div>
//     );
// };

// export default MainContain;






import React, { useState, useEffect } from "react";
import { NavLink, Outlet, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa'; // Ensure `react-icons` is installed
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const MainContain = () => {
    const [expanded, setExpanded] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        // Listen for authentication status
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        });
        return () => unsubscribe(); // Clean up the subscription on unmount
    }, [auth]);

    const handleToggle = () => {
        setExpanded(prevExpanded => !prevExpanded);
    };

    // Redirect to login if not authenticated
    if (!isAuthenticated) {
        return <Navigate to="/User5" />;
    }
    return (
        <div className="container mt-4" style={{ padding: "8px" }}>
            <h1 className="text-center border-bottom fw-bolder" style={{ color: "white", borderRadius: "5px", padding: "10px" }}>
                Welcome Back to ICG Club
            </h1>
            <Navbar expanded={expanded} expand="lg" variant="light" style={{ color: "white", borderRadius: "5px", padding: "10px" }}>
                <Navbar.Brand href="#" style={{ color: 'white' }}>ICG Club</Navbar.Brand>
                <Navbar.Toggle  aria-controls="basic-navbar-nav" 
                
                    onClick={handleToggle} 
                    style={{ color: 'white',backgroundColor: 'white' }} // Optional: customize toggle button
                />
                <Navbar.Collapse id="basic-navbar-nav" style={{ padding: "0 1rem" }}>
                    <Nav className="me-auto">
                        {/* User Links */}
                        <NavLink 
                            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
                            to="/dataAdmin" 
                            style={{ color: 'white', marginLeft: "10px" }}
                            onClick={handleToggle}
                        >
                            Add Data
                        </NavLink>
                        <NavLink 
                            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
                            to="/user5" 
                            style={{ color: 'white', marginLeft: "10px" }}
                            onClick={handleToggle}
                        >
                            All Data User
                        </NavLink>
                        <NavLink 
                            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
                            to="/affilate" 
                            style={{ color: 'white', marginLeft: "10px" }}
                            onClick={handleToggle}
                        >
                            Affilate List
                        </NavLink>
                    </Nav>
                    <NavLink 
                        className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
                        to="/" 
                        style={{ color: 'white', marginLeft: "10px" }}
                        onClick={handleToggle}
                    >
                        SignOut
                    </NavLink>
                </Navbar.Collapse>
            </Navbar>

            {/* submenu */}

            <div className="container main-content">
                <Outlet />
            </div>
        </div>
    );
};

export default MainContain;
