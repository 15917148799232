
import React from "react";
import { Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth"; // Using Firebase auth, if you're using it

// ProtectedRoute component to protect routes
const ProtectedRoute = ({ children }) => {
    const auth = getAuth();
    const user = auth.currentUser; // Get the current authenticated user from Firebase

    // If the user is authenticated (currentUser exists), render the protected route (children)
    // Otherwise, redirect the user to the login page ("/")
    return user ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
