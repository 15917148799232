
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Swal from 'sweetalert2';
import './User.css';
import { HiMiniTableCells } from 'react-icons/hi2';
import { ref, set, remove } from 'firebase/database';
import { database } from './db_user'; // Adjust based on your setup
import { FaRegEdit } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

AOS.init(); // Initialize AOS
function Datauser(useEffect) {

  const navigate = useNavigate(); // Initialize the useNavigate hook
  const [clients, setClients] = useState([]);
  const [newClient, setNewClient] = useState({
    id: '',
    clientId: '',
    clientName: '',
    clientNumber: '',
    clientTelegram: '',
    deposit: '',
    date: '',
    registerAccount: '',
    stage: '',
    description: '',
    staffName: '',
  });

  const [loading, setLoading] = useState(false); // Loading state

  // List of staff names
  const staffNames = [
"MKQ", "PLO", "ABC", "AFP", "AFJ", "ABA", "MKP", "AFH", "AFI", "AFS", "AFO", "AFM", "MKD", "RKA", "CS"
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClient(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddClient = (e) => {
    e.preventDefault();
    if (newClient.clientId && newClient.clientName) {
      setLoading(true); // Set loading state to true
      const newClientData = { ...newClient, id: Date.now().toString() }; // Use a timestamp as a unique ID

      set(ref(database, 'clients/' + newClientData.id), newClientData)
        .then(() => {
          // Update local state immediately with the new client
          setClients(prevClients => [...prevClients, newClientData]);
          Swal.fire('Added!', 'The client has been added.', 'success');
          handleReset();
        })
        .catch((error) => {
          console.error('Error adding client to Firebase:', error);
          Swal.fire('Error!', 'There was an error adding the client.', 'error');
        })
        .finally(() => {
          setLoading(false); // Reset loading state
        });
    }
  };

  const handleReset = () => {
    setNewClient({
      id: '',
      clientId: '',
      clientName: '',
      clientNumber: '',
      clientTelegram: '',
      deposit: '',
      date: '',
      registerAccount: '',
      stage: '',
      description: '',
      staffName: '',
    });
  };

  const handleEditClient = (client) => {
    setNewClient(client);
  };

  const handleUpdateClient = (e) => {
    e.preventDefault();
    const updatedClient = { ...newClient }; // Create a copy of the newClient

    set(ref(database, 'clients/' + updatedClient.id), updatedClient)
      .then(() => {
        setClients(prevClients => prevClients.map(client => 
          client.id === updatedClient.id ? updatedClient : client
        ));
        Swal.fire('Updated!', 'The client has been updated.', 'success');
        handleReset();
      })
      .catch((error) => {
        console.error('Error updating client in Firebase:', error);
        Swal.fire('Error!', 'There was an error updating the client.', 'error');
      });
  };

  const handleDeleteClient = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        remove(ref(database, 'clients/' + id))
          .then(() => {
            setClients(prevClients => prevClients.filter(client => client.id !== id));
            Swal.fire('Deleted!', 'The client has been deleted.', 'success');
          })
          .catch((error) => {
            console.error('Error deleting client in Firebase:', error);
            Swal.fire('Error!', 'There was an error deleting the client.', 'error');
          });
      }
    });
  };


  
  return (
    <div data-aos="fade-up-left" className="container bg-dark mt-5">
      <h2 className="text-center fw-bold border-bottom " style={{color: 'white'}}>Please fill your client details</h2>
      {/* Form to add or edit a client */}
      <form onSubmit={newClient.id ? handleUpdateClient : handleAddClient} className="mb-5">
        <div className="row p-1">
          <h5 className="mb-1">{newClient.id ? 'Edit Client' : 'Add New Client'}</h5>

          <div className="col-md-4 col-sm-6">
            <label htmlFor="clientId" className="form-label color">Client ID</label>
            <input
              type="text"
              name="clientId"
              autoComplete='off'
              value={newClient.clientId}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter Client ID"
              required
              id="clientId"
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <label htmlFor="clientName" className="form-label color">Client Name</label>
            <input
              type="text"
              name="clientName"
              autoComplete='off'
              value={newClient.clientName}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter Client Name"
              required
              id="clientName"
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <label htmlFor="clientNumber" className="form-label color">Client Number</label>
            <input
              type="text"
              name="clientNumber"
              autoComplete='off'
              value={newClient.clientNumber}
              className="form-control"
              required
              placeholder="Enter Client Number"
              id="clientNumber"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <label htmlFor="clientTelegram" className="form-label color">Client Telegram</label>
            <input
              type="text"
              name="clientTelegram"
              autoComplete='off'
              value={newClient.clientTelegram}
              className="form-control"
              placeholder="Enter Client Telegram"
              id="clientTelegram"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <label htmlFor="deposit" className="form-label color">Deposit</label>
            <input
              type="number"
              name="deposit"
              autoComplete='off'
              value={newClient.deposit}
              className="form-control"
              placeholder="Enter Deposit Amount"
              id="deposit"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <label htmlFor="date" className="form-label color">Date</label>
            <input
              type="date"
              autoComplete='off'
              name="date"
              required
              value={newClient.date}
              className="form-control"
              id="date"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <label htmlFor="registerAccount" className="form-label color">Type Account</label>
            <select
              name="registerAccount"
              autoComplete='off'
              value={newClient.registerAccount}
              onChange={handleInputChange}
              className="form-select"
              id="registerAccount"
              required
            >
              <option value="">Select Type Account</option>
              <option value="No Deposit">No Deposit</option>
              <option value="Deposit">Deposit</option>
            </select>
          </div>
          <div className="col-md-4 col-sm-6">
            <label htmlFor="stage" className="form-label color">Stage</label>
            <select
              name="stage"
              value={newClient.stage}
              autoComplete='off'
              onChange={handleInputChange}
              className="form-select"
              id="stage"
            >
              <option value="">Select Stage</option>
              <option value="Level 1">Level 1</option>
              <option value="Level 2">Level 2</option>
              <option value="Level 3">Level 3</option>
              <option value="Level 4">Level 4</option>
              <option value="Level 5">Level 5</option>
            </select>
          </div>
          <div className="col-md-4 col-sm-6">
            <label htmlFor="description" className="form-label color">Description</label>
            <input
              type="text"
              name="description"
              autoComplete='off'
              value={newClient.description}
              className="form-control"
              placeholder="Enter Description"
              id="description"
              onChange={handleInputChange}
            />
          </div>

          {/* Staff Name Selection */}
          <div className="col-md-4 col-sm-6">
            <label htmlFor="staffName" className="form-label color">Staff Name</label>
            <select
              name="staffName"
              autoComplete='off'
              value={newClient.staffName}
              onChange={handleInputChange}
              className="form-select"
              id="staffName"
              required
            >
              <option value="">Select Your Affilate</option>
              {staffNames.map((name, index) => (
                <option key={index} value={name}>{name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col border-top mt-1 mb-5 d-flex align-items-end p-3">
          <button type="submit" className="btn btn-primary">
            {loading ? 'Loading...' : (newClient.id ? 'Update Client' : 'Add Client')}
          </button>
          <button onClick={() => navigate('/')} className="btn btn-danger " style={{marginLeft: '10px'}}>Sign Out</button>

        </div>
        {/* Sign Out Button */}
      {/* <div className="text-end mb-3">
      </div> */}
        
      </form>

      {/* Client Table */}
      {clients.length > 0 && (
        <div className='bg-dark p-1' style={{ borderRadius: '10px' }}>
          <HiMiniTableCells style={{ color: 'white', fontSize: '2rem' }} />
          <span style={{ color: 'white', fontSize: '15px' }}>Display Client User01</span>
          <div className='border-bottom mb-2' />
          <div className="table-responsive">
            <table className="table table-dark table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Date</th>
                  <th>C.ID</th>
                  <th>C.Name</th>
                  <th>C.Number</th>
                  <th>C.TG</th>
                  <th>DP($)</th>
                  <th>Rg.Account</th>
                  <th>Stage</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {clients.map(client => (
                  <tr key={client.id}>
                    <td>{client.id}</td>
                    <td>{client.date}</td>
                    <td>{client.clientId}</td>
                    <td>{client.clientName}</td>
                    <td>{client.clientNumber}</td>
                    <td>{client.clientTelegram}</td>
                    <td>{client.deposit}</td>
                    <td>{client.registerAccount}</td>
                    <td>{client.stage}</td>
                    <td>
                      <button onClick={() => handleEditClient(client)} className="btn btn-warning btn-sm"><FaRegEdit /></button>
                      <button onClick={() => handleDeleteClient(client.id)} className="btn btn-danger btn-sm ml-2"><MdDeleteOutline /></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default Datauser;
