

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const auth = getAuth();
    const navigate = useNavigate();

    async function handleSignIn(e) {
        e.preventDefault();
        setLoading(true);
        setError(''); // Reset error on new login attempt

        try {
            // Attempt to sign in with the provided credentials
            await signInWithEmailAndPassword(auth, email, password);
            
            // Navigate based on password content
            if (password.includes("admin")) {
                navigate("/user5");
            } else if (password.includes("user")) {
                navigate("/datauser");
            } else {
                setError("Invalid password. You must include either 'admin' or 'user'.");
            }
        } catch (error) {
            setError(`Error: ${error.message}`);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div style={{width: '400px', height:'650px', margin:'auto' ,display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <form onSubmit={handleSignIn} style={{background:'white',padding:'35px',height:'300px',borderRadius:'8px'}}>
            <h1 style={{marginTop:'-10px',marginBottom:'20px'}}>Login</h1>

                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{ padding: '8px', margin: '8px 0', width: '100%', borderRadius:'8px' }}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    style={{ padding: '8px', margin: '8px 0', width: '100%', borderRadius:'8px' }}
                />
                <button
                    type="submit"
                    disabled={loading}
                    style={{
                        padding: '10px',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        border: 'none',
                        width: '100%',
                        cursor: 'pointer',
                        borderRadius:'8px'
                    }}
                >
                    {loading ? "Logging in..." : "Log in"}
                </button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
}

export default Login;
